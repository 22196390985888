import firebaseConfig from '../firebase.config';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  Auth
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { AUTH_HEADER } from '../settings/constants';

class Firebase {
  private static instance: Firebase;
  private refreshTokenTimer: NodeJS.Timeout | null;
  private readonly auth: Auth;

  constructor() {
    initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.auth.currentUser?.getIdToken(true).then((token) => {
      localStorage.setItem(AUTH_HEADER, token);
    });
    this.auth.onAuthStateChanged(async (user) => {
      console.log('onAuthStateChanged', user);
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem(AUTH_HEADER, token);
      } else {
        localStorage.removeItem(AUTH_HEADER);
      }
    });

    this.createRefreshTokenTimer();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Firebase();
    }
    return this.instance;
  }

  getAuth(): Auth {
    return this.auth;
  }

  async login(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(this.auth, email, password);
    this.createRefreshTokenTimer();
  }

  async logout() {
    this.removeRefreshTokenTimer();
    return await signOut(this.auth);
  }

  private refreshToken(): void {
    console.log('Refreshing token...');
    this.auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        localStorage.setItem(AUTH_HEADER, token);
        console.log('Token refreshed.');
      })
      .catch((error) => {
        console.log(error);
        window.location.href = '/login';
      });
  }

  private createRefreshTokenTimer(): void {
    if (this.refreshTokenTimer === null) {
      this.refreshTokenTimer = setInterval(
        () => this.refreshToken(),
        1000 * 5 * 60
      );
      console.log('Refresh token timer created.');
    } else {
      console.log('Refresh token timer already exists.');
    }
  }

  private removeRefreshTokenTimer(): void {
    if (this.refreshTokenTimer !== null) {
      clearInterval(this.refreshTokenTimer);
      this.refreshTokenTimer = null;
      console.log('Refresh token timer removed.');
    } else {
      console.log('Refresh token timer does not exist.');
    }
  }
}

export default Firebase;
