import { NotificationType } from '../settings/bookingTypes';
import { BOOKINGS } from '../settings/constants';

export function buildActionableLinkForNotification(
  notification: NotificationType
): string {
  let href = '#';
  switch (notification.type) {
    case 'booking':
      href = `${BOOKINGS}/${notification.details.booking_id}`;
      break;
    default:
      href = '#';
  }
  return href;
}
