import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  CheckCircleIcon,
  EmojiSadIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  XCircleIcon
} from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PropertyList from '../../components/PropertyList/PropertyList';
import Card from '../../components/PaymentCard/Card';
import { DrawerContext } from '../../context/DrawerContext';
import { del, get, post, put } from '../../helper/fetch';
import { toast } from 'react-toastify';
import { Notes } from '../../settings/bookingTypes';
import Comments from '../../components/Comments/Comments';

const getStatus = (status): ReactElement => {
  switch (status) {
    case 'accepted': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'requested': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <LightBulbIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-yellow-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'rejected': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <XCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'cancelled':
    case 'canceled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
    }
    case 'confirmed':
    case 'completed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-2.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    default: {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">???</p>
      );
      break;
    }
  }
};

type Client = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  joined: string;
  stripeId: string;
  created_at: string;
  referral_code?: string;
  referrer?: any;
  deleted?: boolean;
};

type Property = {
  id: number;
  address: string;
  apartmentNumber?: string;
  name?: number;
  size?: number;
  numberOfBathrooms?: number;
  numberOfBedrooms?: number;
  entryInformation?: string;
  parkingInformation?: string;
};

type Booking = {
  id: number;
  cleanerName: string;
  date: string;
  status: string;
};

type StripeCard = {
  id: string;
  cardType: string;
  expirationMonth: string;
  expirationYear: string;
  lastFourDigit: string;
};

export default function ClientProfile() {
  const { id } = useParams<{ id: string }>();
  const [client, setClient] = useState({} as Client);
  const [bookings, setBookings] = useState([] as Booking[]);
  const [cards, setCards] = useState([] as StripeCard[]);
  const [properties, setProperties] = useState([] as Property[]);
  const [notes, setNotes] = useState([]);
  const history = useHistory();
  const [showAvailability, setShowAvailability] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);

  useEffect(() => {
    if (id) {
      get(`/clients/${id}`)
        .then((r) => {
          setClient(r);
          get(`/clients/${r.id}/bookings`)
            .then((r) => setBookings(r))
            .catch((e) => toast.error(e.error || 'could not fetch bookings'));
          get(`/clients/${r.id}/cards`)
            .then((r) => setCards(r))
            .catch((e) => toast.error(e.error || 'could not fetch cards'));
          get(`/clients/${r.id}/properties`)
            .then((r) => setProperties(r))
            .catch((e) => toast.error(e.error || 'could not fetch properties'));
          get(`/clients/${r.id}/notes`)
            .then((r) => setNotes(r))
            .catch((e) => toast.error(e.error || 'could not fetch notes'));
        })
        .catch((e) => {});
    }
  }, []);

  const saveNewProperty = async (data) => {
    const resp = await post(`/clients/${id}/properties`, data);
    if (resp.status !== 201) {
      const body = await resp.json();
      toast.error(body.error);
    } else {
      toast.success('saved new property');
      //refetch addresses
      get(`/clients/${client.id}/properties`)
        .then((r) => {
          setProperties(r);
        })
        .catch((e) => {});
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };
  const updateProperty = async (data) => {
    const resp = await put(`/clients/${id}/properties/${data.id}`, data);
    if (resp.status !== 200) {
      const body = await resp.json();
      toast.error(body.error);
    } else {
      toast.success('updated property');
      //re-fetch addresses
      get(`/clients/${client.id}/properties`)
        .then((r) => {
          setProperties(r);
        })
        .catch((e) => {});
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };
  const deleteProperty = async (propertyId) => {
    const resp = await del(`/clients/${id}/properties/${propertyId}`);
    if (resp.status !== 200) {
      const body = await resp.json();
      toast.error(`${body.error}`);
    } else {
      toast.success('deleted property');
      //re-fetch addresses
      get(`/clients/${client.id}/properties`)
        .then((r) => {
          setProperties(r);
        })
        .catch((e) => {});
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };

  const editClient = async (data) => {
    const resp = await put(`/clients/${id}`, data);
    if (resp.status !== 200) {
      toast.error('could not update user');
    } else {
      toast.success('updated client');
      //re-fetch addresses
      get(`/clients/${id}`)
        .then((r) => {
          setClient(r);
        })
        .catch((e) => {});
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-100">
      <main className="py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {client.name}{' '}
              </h1>
              <div className="text-sm font-medium text-gray-500">
                joined on <time dateTime="2020-08-25">{client.joined}</time>
                <div
                  className={'hover:bg-gray-50 cursor-pointer'}
                  onClick={() =>
                    history.push(`/clients/${client.referrer?.id}`)
                  }
                >
                  {client.referrer
                    ? `Referred by ${client.referrer?.given_name} ${client.referrer?.family_name}`
                    : 'no referrer'}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              onClick={() =>
                dispatch({
                  type: 'OPEN_DRAWER',
                  drawerComponent: 'EDIT_CLIENT_FORM',
                  data: {
                    id: client.id,
                    firstName: client.firstName,
                    lastName: client.lastName,
                    phoneNumber: client.phoneNumber,
                    email: client.email,
                    joined: client.joined,
                    onSubmit: (data) => {
                      editClient(data);
                    }
                  }
                })
              }
            >
              Edit Client Info
            </button>
            {/*<button*/}
            {/*    type="button"*/}
            {/*    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"*/}
            {/*>*/}
            {/*  Call*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    client id: {client.id}
                  </p>
                  <button className='text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md'
                          onClick={() => {
                            const newStatus = !client.deleted;
                            put(`/clients/${id}/disable`, { deleted: newStatus })
                              .then((r) => {
                                if (r.status !== 200) {
                                  throw Error('could not update user');
                                } else {
                                  toast.success(`updated client: disabled=${newStatus}`);
                                  //re-fetch addresses
                                  get(`/clients/${id}`) 
                                    .then((r) => {
                                      setClient(r);
                                    })
                                    .catch((e) => {
                                      toast.error(e.error || 'could not refetch user');
                                    });
                                }
                              })
                              .catch((e) =>{
                                toast.error('could not update user')
                              });
                          }}>
                    {client.deleted ? 'Enable' : 'Disable'}
                  </button>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Stripe ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {client.stripeId}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {client.email}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Referral Code
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {client.referral_code}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {client.phoneNumber}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Payment Cards
                      </dt>
                      <div>
                        {cards.map((c) => {
                          return (
                            <div className="lg:w-1/3 md:w-1/2" key={c.id + '-wrp'}>
                              <Card
                                id={c.id}
                                key={c.id}
                                name={'missing name'}
                                color={''}
                                cardType={c.cardType}
                                lastFourDigit={c.lastFourDigit}
                                expirationYear={c.expirationYear}
                                expirationMonth={c.expirationMonth}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            </section>

            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Properties
                    </h2>
                  </div>
                  <div className="p-4">
                    <PropertyList
                      properties={properties}
                      addNewProperty={() =>
                        dispatch({
                          type: 'OPEN_DRAWER',
                          drawerComponent: 'ADD_PROPERTY_FORM',
                          data: {
                            userId: id,
                            propertyId: null,
                            property: {},
                            onSubmit: (data) => {
                              saveNewProperty(data);
                            },
                            onDelete: () => {}
                          }
                        })
                      }
                      editProperty={(property) =>
                        dispatch({
                          type: 'OPEN_DRAWER',
                          drawerComponent: 'ADD_PROPERTY_FORM',
                          data: {
                            userId: id,
                            propertyId: null,
                            property: property,
                            onSubmit: (p) => {
                              updateProperty(
                                Object.assign(p, { id: property.id })
                              );
                            },
                            onDelete: () => deleteProperty(property.id)
                          }
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* Comments*/}
            {client && (
              <Comments
                comments={notes}
                onSubmit={(newNote) => {
                  post(`/clients/${id}/notes`, { note: newNote })
                    .then((r) => {
                      if (r.status !== 201) {
                        throw Error('could not add note');
                      } else {
                        toast.success('saved new note');
                        window.location.reload();
                      }
                    })
                    .catch((e) => toast.error(e));
                }}
              />
            )}
          </div>

          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                Timeline
              </h2>

              {/* Activity Feed */}
              <div className="mt-6 flow-root">
                <ul className="-mb-8">
                  {bookings.map((booking, itemIdx) => (
                    <li key={booking.id}>
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div>{getStatus(booking.status)}</div>
                          <div
                            onClick={() =>
                              history.push(`/bookings/${booking.id}`)
                            }
                            className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4 hover:bg-gray-100 cursor-pointer"
                          >
                            <div className={''}>
                              <p className="text-sm text-grey-900">
                                With {booking.cleanerName}{' '}
                                {dayjs(booking.date).format('MMM-DD')}
                              </p>
                            </div>
                            <div className="text-right text-sm whitespace-nowrap text-gray-500">
                              <p>{booking.status}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6 flex flex-col justify-stretch">
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Load More
                </button>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
