import React, { useState } from 'react';
import { PlusSmIcon, MinusIcon } from '@heroicons/react/solid';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DropDownBasic from '../DropDownBasic/DropDownBasic';

type SearchParams = {
  filterParams: any;
  onSearch: any;
  onChangeNumberPerPage: any;
  onClear: any;
};


export const Regions = [
  { id: 'Calgary', name: 'Calgary', unavailable: false },
  { id: 'Edmonton', name: 'Edmonton', unavailable: false },
  { id: 'Vancouver', name: 'Vancouver', unavailable: false },
  { id: 'Other', name: 'Other', unavailable: false }
];


export default function SearchForm({
  onSearch,
  onClear,
  filterParams,
  onChangeNumberPerPage
}: SearchParams) {
  const [clientSectionShown, setClientSectionShown] = useState(false);
  const [cleanerSectionShown, setCleanerSectionShown] = useState(false);
  const [bookingSectionShown, setBookingSectionShown] = useState(false);
  const [sortSectionShown, setSortSectionShown] = useState(false);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [selectedRegion, setRegion] = useState(null);


  const [sortSelected, setSortSelected] = useState('rdate');

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      dateFrom: filterParams.dateFrom || null,
      dateTo: filterParams.dateTo || null,
      requested: filterParams.requested || true,
      accepted: filterParams.accepted || true,
      rejected: filterParams.rejected || true,
      cancelled: filterParams.cancelled || true,
      confirmed: filterParams.confirmed || true,
      paid: filterParams.paid || true,
      numberPerPage: filterParams.numberPerPage || 20,
      sortSelected: filterParams.sort || 'rdate'
    }
  });

  const resetDefaults = () => {
    setValue('requested', true);
    setValue('accepted', true);
    setValue('rejected', true);
    setValue('cancelled', true);
    setValue('confirmed', true);
    setValue('paid', true);
    setSortSelected('rdate');

    setValue('clientFirstName', null);
    setValue('clientLastName', null);
    setValue('city', null);

    setValue('cleanerFirstName', null);
    setValue('cleanerLastName', null);

    setDateFrom(null);
    setDateTo(null);
    setValue('numberPerPage', 20);

    onClear();
  };

  const sortChange = (event) => {
    setSortSelected(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        let statuses = [];
        if (data.requested) statuses.push('requested');
        if (data.accepted) statuses.push('accepted');
        if (data.rejected) statuses.push('rejected');
        if (data.confirmed) statuses.push('confirmed');
        if (data.paid) statuses.push('paid');
        if (data.cancelled) {
          statuses.push('cancelled');
          statuses.push('canceled');
        }
        // @ts-ignore
        let searhObj = {
          statuses,
          //@ts-ignore
          clientFirstName: data.clientFirstName,
          //@ts-ignore
          clientLastName: data.clientLastName,
          //@ts-ignore
          city: data.city,
          numberPerPage: data.numberPerPage,
          //@ts-ignore
          sort: data.sorting
        };
        if (dateFrom) {
          searhObj['dateFrom'] = dayjs(dateFrom).format('YYYY-MM-DD');
        }
        if (dateTo) {
          searhObj['dateTo'] = dayjs(dateTo).format('YYYY-MM-DD');
        }
        //@ts-ignore
        if (data.clientFirstName) {
          //@ts-ignore
          searhObj['clientFirstName'] = data.clientFirstName;
        }
        //@ts-ignore
        if (data.clientLastName) {
          //@ts-ignore
          searhObj['clientLastName'] = data.clientLastName;
        }
        //@ts-ignore
        if (data.cleanerFirstName) {
          //@ts-ignore
          searhObj['cleanerFirstName'] = data.cleanerFirstName;
        }
        //@ts-ignore
        if (data.cleanerLastName) {
          //@ts-ignore
          searhObj['cleanerLastName'] = data.cleanerLastName;
        }
        //@ts-ignore
        if (data.city) {
          //@ts-ignore
          searhObj['city'] = data.city;
        }
        searhObj['region'] = selectedRegion?.id;
        onSearch(searhObj);
      })}
    >
      <div className={'bg-white p-4'}>
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Booking Filters
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Filters are implemented as OR
            </p>
          </div>
        </div>

        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Client Filters
          </h3>
          <button
            onClick={() => setClientSectionShown(!clientSectionShown)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {clientSectionShown ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>

          <div
            className={`${clientSectionShown ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="clientFirstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('clientFirstName')}
                  type="text"
                  name="clientFirstName"
                  id="clientFirstName"
                  autoComplete="clientFirstName"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="clientLastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('clientLastName')}
                  type="text"
                  name="clientLastName"
                  id="clientLastName"
                  autoComplete="family-name"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">*/}
            {/*  <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">*/}
            {/*    City*/}
            {/*  </label>*/}
            {/*  <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
            {/*    <input*/}
            {/*        {...register('CHANGEME')}*/}
            {/*        type="text"*/}
            {/*        name="city"*/}
            {/*        id="city"*/}
            {/*        autoComplete="address-level2"*/}
            {/*        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Cleaner Filters
          </h3>
          <button
            onClick={() => setCleanerSectionShown(!cleanerSectionShown)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {cleanerSectionShown ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
          <div
            className={`${cleanerSectionShown ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Region
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <DropDownBasic
                  label={'Region'}
                  options={Regions}
                  selected={selectedRegion}
                  onSelect={setRegion}
                />
              </div>
            </div>


            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cleanerFirstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('cleanerFirstName')}
                  type="text"
                  name="cleanerFirstName"
                  id="cleanerFirstName"
                  autoComplete="cleanerFirstName"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cleanerLastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  {...register('cleanerLastName')}
                  type="text"
                  name="cleanerLastName"
                  id="cleanerLastName"
                  autoComplete="family-name"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Booking Filters
          </h3>
          <button
            onClick={() => setBookingSectionShown(!bookingSectionShown)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {bookingSectionShown ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
          <div
            className={`${bookingSectionShown ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-email"
                    >
                      Booking Status
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('requested')}
                            id="requested"
                            name="requested"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="requested"
                            className="font-medium text-gray-700"
                          >
                            requested
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('confirmed')}
                            id="confirmed"
                            name="confirmed"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="confirmed"
                            className="font-medium text-gray-700"
                          >
                            confirmed
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              {...register('accepted')}
                              id="accepted"
                              name="accepted"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="accepted"
                              className="font-medium text-gray-700"
                            >
                              accepted
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              {...register('rejected')}
                              id="rejected"
                              name="rejected"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="rejected"
                              className="font-medium text-gray-700"
                            >
                              rejected
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              {...register('paid')}
                              id="paid"
                              name="paid"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="paid"
                              className="font-medium text-gray-700"
                            >
                              paid
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              {...register('cancelled')}
                              id="cancelled"
                              name="cancelled"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="cancelled"
                              className="font-medium text-gray-700"
                            >
                              cancelled
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Dates
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="max-w-lg">
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <label
                            htmlFor="push-everything"
                            className="ml-3 block text-sm font-medium text-gray-700 w-20"
                          >
                            From Date
                          </label>
                          <DatePicker
                            className={'w-32'}
                            selected={dateFrom}
                            onChange={(val) => setDateFrom(val)}
                          />
                        </div>
                        <div className="flex items-center">
                          <label
                            htmlFor="push-everything"
                            className="ml-3 block text-sm font-medium text-gray-700 w-20"
                          >
                            To Date
                          </label>
                          <DatePicker
                            selected={dateTo}
                            onChange={(val) => setDateTo(val)}
                            className={'w-32'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Sort By
          </h3>
          <button
            onClick={() => setSortSectionShown(!sortSectionShown)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {sortSectionShown ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
          <div
            className={`${sortSectionShown ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="date"
                            name="sorting"
                            type="radio"
                            value="date"
                            checked={sortSelected === 'date'}
                            onChange={sortChange}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="date"
                            className="font-medium text-gray-700"
                          >
                            date ascending
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="rdate"
                            name="sorting"
                            type="radio"
                            value="rdate"
                            checked={sortSelected === 'rdate'}
                            onChange={sortChange}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="rdate"
                            className="font-medium text-gray-700"
                          >
                            date descending
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="name"
                            name="sorting"
                            type="radio"
                            value="name"
                            checked={sortSelected === 'name'}
                            onChange={sortChange}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="name"
                            className="font-medium text-gray-700"
                          >
                            cleaner ascending
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="rname"
                            name="sorting"
                            type="radio"
                            value="rname"
                            checked={sortSelected === 'rname'}
                            onChange={sortChange}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="rname"
                            className="font-medium text-gray-700"
                          >
                            cleaner descending
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 sm:pt-10 max-w-sm">
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Number of Results Per Page
            </label>
            <select
              id="location"
              name="location"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue="50"
              onChange={(e) => onChangeNumberPerPage(parseInt(e.target.value))}
            >
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={resetDefaults}
              className="w-6/12 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Clear
            </button>
            <button
              type="submit"
              className="w-6/12 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
