import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AUTH_HEADER,
  BOOKINGS,
  COUPONS,
  CLEANER,
  CLEANERS,
  CUSTOMER,
  CUSTOMERS,
  DASHBOARD,
  LOGIN,
  SINGLE_BOOKING,
  AVAILABILITY,
  SETTINGS,
  NOTIFICATIONS, GROUP_BOOKINGS, CLEANER_SCHEDULE, WORK_AREAS
} from './settings/constants';
import CleanerProfile from './containers/CleanerProfile/CleanerProfile';
import InLineLoader from './components/InlineLoader/InlineLoader';
import Bookings from './containers/Bookings/Bookings';
import ClientProfile from './containers/ClientProfile/ClientProfile';
import SingleBookingView from './containers/SingleBookingView/SingleBookingView';
import { useAuthState, useIdToken } from 'react-firebase-hooks/auth';
import { Coupons } from './containers/Coupons/Coupons';
import WorkAreas from './containers/WorkAreas/WorkAreas';
import CleanerAvailabilitySearch from './containers/CleanerAvailabilitySearch/CleanerAvailabilitySearch';
import Settings from './containers/Settings/Settings';
import Notifications from './containers/Notifications/Notifications';
import NewGroupBooking from "./containers/NewGroupBooking/NewGroupBooking";
import TimeOff from "./containers/CleanerProfile/TimeOff/TimeOff";

const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const Customers = lazy(() => import('./containers/Customers/Customers'));
const Cleaners = lazy(() => import('./containers/Cleaner/Cleaners'));
const Login = lazy(() => import('./containers/Login/Login'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */
function PrivateRoute({ user, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user || localStorage.getItem(AUTH_HEADER) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const Routes = ({ firebaseApp }) => {
  const [user, loading, error] = useAuthState(firebaseApp.getAuth());

  return (
    <>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute user={user} exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={SINGLE_BOOKING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleBookingView />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={BOOKINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Bookings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={GROUP_BOOKINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <NewGroupBooking />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={CUSTOMER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ClientProfile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={CLEANER_SCHEDULE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TimeOff />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={CLEANER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CleanerProfile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={CLEANERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Cleaners />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={AVAILABILITY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CleanerAvailabilitySearch />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={WORK_AREAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WorkAreas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute user={user} path={NOTIFICATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Notifications />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
