import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { useForm } from 'react-hook-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import dayjs from 'dayjs';
import DropDownBasic from '../DropDownBasic/DropDownBasic';
import { Regions } from '../SearchForm/SearchForm';
type SearchParams = {
  filterParams: any;
  onSearch: any;
  onClear: any;
};

export default function AvailabilitySearchForm({
  onSearch,
  onClear,
  filterParams
}: SearchParams) {
  const [address, setAddress] = useState(
    filterParams?.address ? filterParams?.address : null
  );
  const [bookingDate, setBookinDate] = useState(
    filterParams?.date ? dayjs(filterParams?.date) : dayjs().toDate()
  );
  const [selectedRegion, setRegion] = useState(null);


  const { handleSubmit } = useForm({});

  const resetDefaults = () => {
    setAddress(null);
    setRegion(null);
    setBookinDate(dayjs().toDate());
    onClear();
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        let searhObj = {
          address: address?.label || '',
          region: selectedRegion?.id || null,
          date: dayjs(bookingDate).format('YYYY-MM-DD')
        };
        onSearch(searhObj);
      })}
    >
      <div className={'bg-white p-4'}>
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Cleaner Filters
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Filters are implemented as OR
            </p>
          </div>
        </div>

        <div
          className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="region"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Region
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <DropDownBasic
              label={null}
              options={Regions}
              selected={selectedRegion}
              onSelect={setRegion}
            />
          </div>
        </div>

        <div className="divide-y divide-gray-200 sm:pt-10">
          <div className={`space-y-6 sm:space-y-5`}>
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="">
                        <div className="flex items-center h-5">
                          <div className={'min-w-full w-full'}>
                            <GooglePlacesAutocomplete
                              apiKey={'AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM'}
                              minLengthAutocomplete={5}
                              debounce={100}
                              onLoadFailed={(error) => {
                                console.log(error);
                                setAddress('');
                              }}
                              autocompletionRequest={{
                                componentRestrictions: { country: ['ca'] },
                                types: ['address']
                              }}
                              selectProps={{
                                address,
                                onChange: setAddress,
                                defaultInputValue: address,
                                placeholder: 'Enter an address',
                                name: 'address',
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    width: '100%'
                                  }),
                                  menu: (provided) => ({
                                    ...provided
                                  }),
                                  option: (provided) => ({
                                    ...provided
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    boxShadow: 'none',
                                    border: 'none',
                                    ring: 'none'
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    border: '1px solid #87be4f',
                                    ring: 'none',
                                    marginBottom: '10px',
                                    height: '48px',
                                    boxShadow: `0 0 0 1px 'orange'`,
                                    '&:focus': {
                                      outline: 'none',
                                      border: '1px solid #87be4f',
                                      boxShadow: 'green'
                                    },
                                    '&:hover': {
                                      outline: 'none',
                                      border: '1px solid #87be4f',
                                      boxShadow: 'none'
                                    }
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="numberPerPage"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Date
                  </label>
                  <div className={'mt-1 sm:mt-0 sm:col-span-2'}>
                    <DatePicker
                      selected={dayjs(bookingDate).toDate()}
                      onChange={setBookinDate}
                      className={'chat-input mx-24 my-2'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 sm:pt-10"></div>
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={resetDefaults}
              className="w-6/12 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            >
              Clear
            </button>
            <button
              type="submit"
              className="w-6/12 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
