import React, { ReactElement } from 'react';

import { ChevronRightIcon, PlusCircleIcon } from '@heroicons/react/solid';

export default function AddNewListCard({ title, onClick }: { title: string; onClick: () => void }){
  return (
    <div className="bg-grey shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        <li key={title}>
          <div
            onClick={() => {
              onClick();
            }}
            className="block hover:bg-gray-50 cursor-pointer"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center justify-center">
                <p className={'text-lg font-medium pr-5'}>{title}</p>
                <PlusCircleIcon
                  className="h-10 w-10 text-green-400"
                  aria-hidden="true"
                >
                  {' '}
                </PlusCircleIcon>
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
