import React, { useCallback, useContext } from 'react';
import SettingsCard from '../../components/SettingsCard/SettingsCard';
import { DrawerContext } from '../../context/DrawerContext';
import { styled } from 'baseui';
import {
  CouponIcon,
  InboxIcon,
  Members,
  OrderIcon,
  ProductIcon,
  SidebarCategoryIcon,
  SiteSettings,
  UploadIcon
} from '../../components/AllSvgIcon';
import { Col as Column, Grid, Row } from '../../components/FlexBox/FlexBox';
import { useHistory } from 'react-router-dom';

const Col = styled(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0
    }
  }
}));

export default function Settings() {
  let history = useHistory();

  const { state, dispatch } = useContext(DrawerContext);

  const openStaffForm = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'STAFF_MEMBER_FORM' }),
    [dispatch]
  );

  const openCategoryForm = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'CATEGORY_FORM' }),
    [dispatch]
  );

  const openProductForm = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'PRODUCT_FORM' }),
    [dispatch]
  );

  const openCouponForm = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'CAMPAING_FORM' }),
    [dispatch]
  );

  const openNotificationSettings = useCallback(
    () =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'NOTIFICATION_SETTINGS_FORM'
      }),
    [dispatch]
  );

  const openTiersForm = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'TIER_SETTINGS_FORM' }),
    [dispatch]
  );

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={6}>
          <SettingsCard
            icon={<Members />}
            title="Staff Members (Admins)"
            subtitle="Manage employees and their admin permissions"
            onClick={() => {}}
          />
        </Col>
        <Col md={6}>
          <SettingsCard
            icon={<InboxIcon width="56px" height="56px" />}
            title="Notification Settings"
            subtitle="Update notification settings"
            onClick={openNotificationSettings}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <SettingsCard
            icon={<SiteSettings />}
            title="Tier Settings"
            subtitle="Update Cleaner Tiers"
            onClick={openTiersForm}
          />
        </Col>

        {/*<Col md={6}>*/}
        {/*  <SettingsCard*/}
        {/*      icon={<SidebarCategoryIcon width='56px' height='56px'/>}*/}
        {/*      title='Add Categories'*/}
        {/*      subtitle='Add product categories from here'*/}
        {/*      onClick={openCategoryForm}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>

      {/*<Row>*/}
      {/*  <Col md={6}>*/}
      {/*    <SettingsCard*/}
      {/*        icon={<OrderIcon width='56px' height='56px'/>}*/}
      {/*        title='Add Staff Members'*/}
      {/*        subtitle='Add your staff members from here'*/}
      {/*        onClick={openStaffForm}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*  <Col md={6}>*/}
      {/*    <SettingsCard*/}
      {/*        icon={<CouponIcon width='56px' height='56px'/>}*/}
      {/*        title='Add Coupons'*/}
      {/*        subtitle='Add coupons from here'*/}
      {/*        onClick={openCouponForm}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Grid>
  );
}
