const config = {
    apiKey: "AIzaSyDA7UGbFHMcEIi2SObXMrfCbKluFbCUTUs",
    authDomain: "homespritz-adminclient.firebaseapp.com",
    projectId: "homespritz-adminclient",
    storageBucket: "homespritz-adminclient.appspot.com",
    messagingSenderId: "315095894078",
    appId: "1:315095894078:web:a0aecc9502dd9b3b5fe96f"
};

export default config;
