import React, { useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

const Comments = ({ comments, onSubmit }) => {
  const [newNote, setNewNote] = useState('');
  const { register, handleSubmit } = useForm();
  return (
    <>
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="notes-title"
                className="text-lg font-medium text-gray-900"
              >
                Notes
              </h2>
            </div>
            <div className="px-4 py-6 sm:px-6">
              <ul className="space-y-8">
                {comments.map((comment, index) => (
                  <li key={index}>
                    <div className="flex space-x-3 ">
                      <div className="flex-shrink-0">
                        {/*<img*/}
                        {/*    className="h-10 w-10 rounded-full"*/}
                        {/*    src={`https://images.unsplash.com/photo-${comment.author_id}`}*/}
                        {/*    alt=""*/}
                        {/*/>*/}
                      </div>
                      <div>
                        <div className="text-sm">
                          <a href="#" className="font-medium text-gray-900">
                            {comment.author?.given_name +
                              ' ' +
                              comment.author?.family_name}
                          </a>
                        </div>
                        <div className="mt-1 text-sm text-gray-700">
                          <p>{comment.content}</p>
                        </div>
                        <div className="mt-2 text-sm space-x-2">
                          <span className="text-gray-500 font-medium">
                            {comment.date_created}
                          </span>{' '}
                          {/*<button type="button" className="text-gray-900 font-medium">*/}
                          {/*  Reply*/}
                          {/*</button>*/}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                {/*<img className="h-10 w-10 rounded-full" src={null} alt=""/>*/}
              </div>
              <div className="min-w-0 flex-1">
                <form
                  onSubmit={handleSubmit(() => {
                    onSubmit(newNote);
                  })}
                >
                  <div>
                    <label htmlFor="comment" className="sr-only">
                      About
                    </label>
                    <textarea
                      id="comment"
                      name="comment"
                      rows={3}
                      className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Add a note"
                      value={newNote}
                      onChange={(e) => setNewNote(e.target.value)}
                    />
                  </div>
                  <div className="mt-3 flex items-center justify-between">
                    <a
                      href="#"
                      className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                    >
                      <QuestionMarkCircleIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>Some HTML is okay.</span>
                    </a>
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Comment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Comments;
