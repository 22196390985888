import React, { useState } from 'react';
import DropDownBasic from '../DropDownBasic/DropDownBasic';

type ReviewCardProps = {
  onChange?: (changed: {
    quality: number;
    reliability: number;
    communication: number;
  }) => void;
  onCancel?: () => void;
  reviewItems: {
    quality: number;
    reliability: number;
    communication: number;
  };
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditCleanerReviewCard = (props: ReviewCardProps) => {
  const generateValues = () => {
    let s = [];
    for (let i = 10; i <= 50; i++) {
      const st = { id: i / 10, name: `${i / 10}`, unavailable: false };
      s.push(st);
    }
    return s;
  };
  generateValues();

  const [rel, setRel] = useState(props.reviewItems?.reliability || 4);
  const [qual, setQual] = useState(props.reviewItems?.quality || 4);
  const [com, setCom] = useState(props.reviewItems?.communication || 4);
  const [stars, setStars] = useState(generateValues());

  return (
    <div className="grid gap-4 grid-cols-1 flex flex-col">
      <div className="flex items-center xl:col-span-1">
        <div className="flex items-center">
          <DropDownBasic
            label={'Quality'}
            options={stars}
            selected={{ id: qual, name: `${qual}`, unavailable: false }}
            onSelect={(val) => {
              setQual(val.id);
            }}
          />
        </div>
        <p className="ml-3 text-sm text-gray-700">
          <span className="sr-only"> out of 5 stars</span>
        </p>
      </div>

      <div className="flex items-center xl:col-span-1">
        <div className="flex items-center">
          <DropDownBasic
            label={'Reliability'}
            options={stars}
            selected={{ id: rel, name: `${rel}`, unavailable: false }}
            onSelect={(val) => {
              setRel(val.id);
            }}
          />
        </div>
        <p className="ml-3 text-sm text-gray-700">
          <span className="sr-only"> out of 5 stars</span>
        </p>
      </div>

      <div className="flex items-center xl:col-span-1">
        <div className="flex items-center">
          <DropDownBasic
            label={'Communication'}
            options={stars}
            selected={{ id: com, name: `${com}`, unavailable: false }}
            onSelect={(val) => {
              setCom(val.id);
            }}
          />
        </div>
        <p className="ml-3 text-sm text-gray-700">
          <span className="sr-only"> out of 5 stars</span>
        </p>
      </div>

      <div className={'flex-row justify-between my-6'}>
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 mr-6 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none"
          onClick={props.onCancel}
        >
          Cancel
        </button>

        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none"
          onClick={() =>
            props.onChange({
              quality: qual,
              reliability: rel,
              communication: com
            })
          }
        >
          Save Reviews
        </button>
      </div>
    </div>
  );
};

export default EditCleanerReviewCard;
