import React from 'react';

const announcements = [
  {
    id: 1,
    title: 'Office closed on July 2nd',
    preview:
      'Cum qui rem deleniti. Suscipit in dolor veritatis sequi aut. Vero ut earum quis deleniti. Ut a sunt eum cum ut repudiandae possimus. Nihil ex tempora neque cum consectetur dolores.'
  },
  {
    id: 2,
    title: 'New password policy',
    preview:
      'Alias inventore ut autem optio voluptas et repellendus. Facere totam quaerat quam quo laudantium cumque eaque excepturi vel. Accusamus maxime ipsam reprehenderit rerum id repellendus rerum. Culpa cum vel natus. Est sit autem mollitia.'
  },
  {
    id: 3,
    title: 'Office closed on July 2nd',
    preview:
      'Tenetur libero voluptatem rerum occaecati qui est molestiae exercitationem. Voluptate quisquam iure assumenda consequatur ex et recusandae. Alias consectetur voluptatibus. Accusamus a ab dicta et. Consequatur quis dignissimos voluptatem nisi.'
  }
];

type Property = {
  id: number;
  address: string;
  name?: number;
  size?: number;
  numberOfBathrooms?: number;
  numberOfBedrooms?: number;
  entryInformation?: string;
  parkingInformation?: string;
};

export default function PropertyList({
  properties = [],
  addNewProperty,
  editProperty
}) {
  return (
    <div>
      <div className="flow-root mt-6">
        <ul className="-my-5 divide-y divide-gray-200">
          {properties.map((p) => (
            <li key={p.id} className="py-5 cursor-pointer hover:bg-gray-100">
              <div
                onClick={() => editProperty(p)}
                className="relative focus-within:ring-2 focus-within:ring-indigo-500 cursor-pointer"
              >
                <h3 className="text-sm font-semibold text-gray-800">
                  <div className="hover:underline focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {p.apartment_number && `#${p.apartment_number}`} {p.address}
                  </div>
                </h3>
                <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                  {p.size} sqr feet, {p.number_of_rooms}{' '}
                  {p.number_of_bedrooms > 1 ? 'bedrooms' : 'bedroom'} and{' '}
                  {p.numberOfBathrooms}{' '}
                  {p.numberOfBathrooms > 1 ? 'bathrooms' : 'bathroom'}
                </p>
                <div className="flex flex-row">
                  <p className="mt-1 text-sm text-gray-800 line-clamp-2 mr-2">
                    Entrance Info:{' '}
                  </p>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    {p.entrance_info}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="mt-1 text-sm text-gray-800 line-clamp-2 mr-2">
                    Parking Info:{' '}
                  </p>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    {p.parking_info}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="mt-1 text-sm text-gray-800 line-clamp-2 mr-2">
                    Other Info:{' '}
                  </p>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    {p.other_info}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6">
        <div
          className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
          onClick={() => addNewProperty()}
        >
          Add New
        </div>
      </div>
    </div>
  );
}
