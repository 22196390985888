import React from 'react';

type availability = {
  monday: {
    from: string;
    till: string;
  };
  tuesday: {
    from: string;
    till: string;
  };
  wednesday: {
    from: string;
    till: string;
  };
  thursday: {
    from: string;
    till: string;
  };
  friday: {
    from: string;
    till: string;
  };
  saturday: {
    from: string;
    till: string;
  };
  sunday: {
    from: string;
    till: string;
  };
};

const getDayTableRows = (availability: availability) => {
  let items = [];
  for (const day in availability) {
    items.push(
      <tr key={day} className={'bg-white'}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {day}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {availability[day]['from']}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {availability[day]['till']}
        </td>
      </tr>
    );
  }

  return items;
};

const CleanerAvailability = ({ availability: availability }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Day
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    From
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    To
                  </th>
                </tr>
              </thead>
              <tbody>
                {getDayTableRows(availability).map((day) => {
                  return day;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CleanerAvailability;
