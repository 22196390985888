import React, { useState } from 'react';
import { PlusSmIcon, MinusIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

type SearchParams = {
  filterParams: any;
  onSearch: any;
  onClear: any;
};

export default function CouponSearchForm({
  onSearch,
  onClear,
  filterParams
}: SearchParams) {
  const [couponSectionShow, setCouponSectionShow] = useState(false);
  const [sortSectionShown, setSortSectionShown] = useState(false);
  const [sortSelected, setSortSelected] = useState('rdate');

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      active: filterParams.active || true,
      numberPerPage: filterParams.numberPerPage || 20,
      sortSelected: filterParams.sort || 'rdate',
      sorting: null
    }
  });

  const resetDefaults = () => {
    setValue('active', true);
    setSortSelected('rdate');
    setValue('numberPerPage', 20);

    onClear();
  };

  const sortChange = (event) => {
    setSortSelected(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        let searhObj = {
          active: data.active,
          numberPerPage: data.numberPerPage,
          sort: data.sorting
        };
        onSearch(searhObj);
      })}
    >
      <div className={'bg-white p-4'}>
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Coupon Filters
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Filters are implemented as OR
            </p>
          </div>
        </div>

        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Coupon Filters
          </h3>
          <button
            onClick={() => setCouponSectionShow(!couponSectionShow)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
          >
            {couponSectionShow ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
          <div
            className={`${couponSectionShow ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('active')}
                            id="active"
                            name="active"
                            type="checkbox"
                            checked={filterParams.active}
                            className="focus:ring-hsgreen h-4 w-4 text-hsgreen border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="active"
                            className="font-medium text-gray-700"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="numberPerPage"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Coupons Per Page
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register('numberPerPage')}
                      type="number"
                      step="any"
                      name="numberPerPage"
                      id="numberPerPage"
                      autoComplete="number"
                      defaultValue={filterParams.numberPerPage || 20}
                      className="max-w-lg block w-full shadow-sm focus:ring-hsgreen focus:border-hsgreen sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 sm:pt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Sort By
          </h3>
          <button
            onClick={() => setSortSectionShown(!sortSectionShown)}
            type="button"
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
          >
            {sortSectionShown ? (
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
          <div
            className={`${sortSectionShown ? '' : 'invisible h-0'} space-y-6 sm:space-y-5`}
          >
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="date"
                            name="sorting"
                            type="radio"
                            value="date"
                            checked={sortSelected === 'date'}
                            onChange={sortChange}
                            className="focus:ring-hsgreen h-4 w-4 text-hsgreen border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="date"
                            className="font-medium text-gray-700"
                          >
                            date ascending
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            {...register('sorting')}
                            id="rdate"
                            name="sorting"
                            type="radio"
                            value="rdate"
                            checked={sortSelected === 'rdate'}
                            onChange={sortChange}
                            className="focus:ring-hsgreen h-4 w-4 text-hsgreen border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="rdate"
                            className="font-medium text-gray-700"
                          >
                            date descending
                          </label>
                        </div>
                      </div>
                      {/*<div className="relative flex items-start">*/}
                      {/*  <div className="flex items-center h-5">*/}
                      {/*    <input*/}
                      {/*        {...register('sorting')}*/}
                      {/*        id="name"*/}
                      {/*        name="sorting"*/}
                      {/*        type="radio"*/}
                      {/*        value="name"*/}
                      {/*        checked={sortSelected === "name"}*/}
                      {/*        onChange={sortChange}*/}
                      {/*        className="focus:ring-hsgreen h-4 w-4 text-hsgreen border-gray-300 rounded"*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  <div className="ml-3 text-sm">*/}
                      {/*    <label htmlFor="name" className="font-medium text-gray-700">*/}
                      {/*      cleaner ascending*/}
                      {/*    </label>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="relative flex items-start">*/}
                      {/*  <div className="flex items-center h-5">*/}
                      {/*    <input*/}
                      {/*        {...register('sorting')}*/}
                      {/*        id="rname"*/}
                      {/*        name="sorting"*/}
                      {/*        type="radio"*/}
                      {/*        value="rname"*/}
                      {/*        checked={sortSelected === "rname"}*/}
                      {/*        onChange={sortChange}*/}
                      {/*        className="focus:ring-hsgreen h-4 w-4 text-hsgreen border-gray-300 rounded"*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  <div className="ml-3 text-sm">*/}
                      {/*    <label htmlFor="rname" className="font-medium text-gray-700">*/}
                      {/*      cleaner descending*/}
                      {/*    </label>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={resetDefaults}
              className="w-6/12 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            >
              Clear
            </button>
            <button
              type="submit"
              className="w-6/12 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
