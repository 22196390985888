import React, { useState } from "react";
import { CheckCircleIcon, ClockIcon, XCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { del } from "../../helper/fetch";
import { toast } from "react-toastify";

type Offer = {
  id: number;
  booking_id: number;
  listing_id: number;
  offer_id: string;
  status: "PENDING" | "ACCEPTED" | "REJECTED"; // Add other possible statuses if needed
  created_at: string;
  expires_at: string;
  listing_title: string;
};

type BookingOffersViewGridProps = {
  offers: Offer[];
  onOfferDeleted?: (offerId: number) => void;
};

const getStatusIcon = (status: string) => {
  switch (status) {
    case "PENDING":
      return <ClockIcon className="w-6 h-6 text-yellow-500" />
    case "ACCEPTED":
      return <CheckCircleIcon className="w-6 h-6 text-green-500" />
    case "REJECTED":
      return <XCircleIcon className="w-6 h-6 text-red-500" />
    default:
      return null
  }
}

export default function BookingOffersViewGrid({ offers, onOfferDeleted }: BookingOffersViewGridProps) {
  const [deletingOffers, setDeletingOffers] = useState<Set<number>>(new Set());

  const handleDeleteOffer = async (offerId: number) => {
    setDeletingOffers((prev) => new Set(prev).add(offerId));
    try {
      await del(`/cleanerlistings/offers/${offerId}`);
      onOfferDeleted(offerId);
      toast.success("Offer deleted successfully");
    } catch (error) {
      console.error("Error deleting offer:", error);
      toast.error("Failed to delete offer");
    } finally {
      setDeletingOffers((prev) => {
        const newSet = new Set(prev);
        newSet.delete(offerId);
        return newSet;
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Service Booking Offers</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {offers.map((offer) => (
          <div key={offer.id} className="bg-white shadow-md rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <img
                  src={`https://api.dicebear.com/6.x/initials/svg?seed=${offer.listing_title}`}
                  alt={offer.listing_title}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span className="font-semibold">{offer.listing_title}</span>
              </div>
              {getStatusIcon(offer.status)}
            </div>
            <div className="text-sm text-gray-600">
              <p>Offer id: {offer.id}</p>
              <p>Status: {offer.status}</p>
              <p>Booking ID: {offer.booking_id}</p>
              <p>Created: {new Date(offer.created_at).toLocaleDateString()}</p>
              <p className='text-blue-700 hover:text-blue-800 hover:cursor-pointer' onClick={() => {
                navigator.clipboard.writeText(`https://cleaner.homespritz.ca/o/${offer.offer_id}`)
                  .then(() => toast.success('copied to clipboard'))
                  .catch(() => toast.error(`could no copy to clipboard. https://cleaner.homespritz.ca/o/${offer.offer_id}`));
              }}>Copy URL</p>
            </div>
            <div className="mt-4">
              <button
                onClick={() => handleDeleteOffer(offer.id)}
                disabled={deletingOffers.has(offer.id)}
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {deletingOffers.has(offer.id) ? (
                  <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                ) : (
                  <TrashIcon className="w-5 h-5 mr-2" />
                )}
                {deletingOffers.has(offer.id) ? "deleting..." : "delete"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}