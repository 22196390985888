import { styled, withStyle } from 'baseui';
import styledd from 'styled-components';

export const Text = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  fontFamily: $theme.typography.primaryFontFamily,
  lineHeight: '1.2',
  marginBottom: '50px'
}));

export const Details = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const CW = styledd.div`
padding: 1vw;
background-color: #F7F7F7;
min-height: 200px;
min-height: 5vh;
.chatBar {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.chatList {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-y: scroll;
  max-height: 300px;
}
.msg-wrapper-mine{
 align-self: flex-end;
 .chat-time{
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #77798c;
  line-height: 1.5;
 }
}

.msg-wrapper-theirs{
 align-self: flex-start;
 .chat-time{
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #77798c;
  line-height: 1.5;
 }
}

#chatBarInput {
  flex: 0 0 80%;
}

.chat-message{
 padding: 9px 14px;
 // font-size: 1.6rem;
 margin-bottom: 5px;
}

.chat {
  flex: 1 1 auto;
  align-self: flex-start;
  background-color: lightgreen;
  
}

.chat.mine {
  align-self: flex-end;
  // background: #0048AA;
  background-color: rgba(46, 112, 250, 0.1);
  // color: #eee;
  border: 1px solid #ddd;
  border-radius: 14px 14px 0 14px;
}

.chat.theirs {
  background: #eee;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}
 `;

export const inputAny = styledd.input`
  .any-input {
      width: 100%;
      height: 48px;
      border-radius: 6px;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid #f1f1f1;
      line-height: 19px;
      padding: 0 18px;
      box-sizing: border-box;
      transition: border-color 0.25s ease;

      &:hover,
      &:focus {
        outline: 0;
      }

      &:focus {
        border-color: #009e7f;
      }

      &::placeholder {
        color: #77798C;
      }`;

export const ChatInputWrapper = styledd.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  
  div {
   width: inherit;
  }
  
  .chat-input {
      width: 100%;
      height: 48px;
      border-radius: 6px;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid #f1f1f1;
      line-height: 19px;
      padding: 0 18px;
      box-sizing: border-box;
      transition: border-color 0.25s ease;

      &:hover,
      &:focus {
        outline: 0;
      }

      &:focus {
        border-color: #009e7f;
      }

      &::placeholder {
        color: #77798C;
      }
  }
`;
