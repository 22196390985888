import React from 'react';
import MasterCard from './image/master-card.png';

import Visa from './image/visa.png';
import {
  PaymentCardWrapper,
  CardLogo,
  CardNumber,
  CardNumTitle,
  Name
} from './PaymentCard.style';

interface Props {
  id: string;
  name: string;
  cardType: string;
  lastFourDigit: string;
  color: string;
  expirationMonth: string;
  expirationYear: string;
}

const Card: React.FC<Props> = ({
  id,
  name,
  cardType,
  lastFourDigit,
  color,
  expirationMonth,
  expirationYear
}) => {
  const logo =
    (cardType === 'MasterCard' && MasterCard) || (cardType === 'Visa' && Visa);

  return (
    <PaymentCardWrapper className="payment-card" color={color}>
      <CardLogo>
        <img src={logo} />
      </CardLogo>
      <CardNumTitle>Card Number</CardNumTitle>
      <CardNumber>
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span className="card-number">{lastFourDigit}</span>
      </CardNumber>
      <Name>{name}</Name>
      <span>
        {expirationMonth}/{expirationYear}
      </span>
    </PaymentCardWrapper>
  );
};

export default Card;
