import { StarIcon } from '@heroicons/react/solid';
import React from 'react';

type ReviewCardProps = {
  onChange?: (changed: ReviewItem[]) => void;
  reviewItems: ReviewItem[];
};

//Value is out of 5
//We only ever do the full stars, no half stars
type ReviewItem = {
  title: string;
  value: number;
  description?: number;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CleanerReviewCard = (props: ReviewCardProps) => {
  return (
    <div className="grid gap-4 grid-cols-2">
      {props.reviewItems &&
        props.reviewItems.map((reviewItem, idx) => (
          <div key={`review-idx-${idx}`}>
            <div className="mt-1 text-sm text-gray-900" key={reviewItem.title}>
              <p className="text-sm font-medium text-gray-500 pr-6">
                {reviewItem.title}
              </p>
            </div>
            <div className="flex items-center xl:col-span-1">
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => {
                  return (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviewItem.value > rating + 0.7
                          ? 'text-yellow-400'
                          : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  );
                })}
              </div>
              <p className="ml-3 text-sm text-gray-700">
                {reviewItem.value}
                <span className="sr-only"> out of 5 stars</span>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CleanerReviewCard;
