import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

type Props = {
  label: string;
  selected: {
    id: number | string;
    name: string;
    unavailable?: boolean;
  };
  options: {
    id: number | string;
    name: string;
    unavailable: boolean;
  }[];
  onSelect: Function;
};

const DropDownBasic: React.FC<Props> = ({
  label,
  selected,
  options,
  onSelect
}) => {
  const [s, setSelected] = useState(selected);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Listbox
      value={s}
      onChange={(value) => {
        setSelected(value);
        onSelect(value);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="w-36 block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className=" w-36 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-hsgreen sm:text-sm">
              <span className="block truncate">{s?.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="w-auto absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((h) => (
                  <Listbox.Option
                    key={label + h.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-hsgreen' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-8 pr-4'
                      )
                    }
                    value={h}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {h.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-hsgreen',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default DropDownBasic;
