import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DrawerContext } from '../../context/DrawerContext';
import { get, post, put } from '../../helper/fetch';
import { Grid, Row, Col } from '../../components/FlexBox/FlexBox';
import SearchForm from '../../components/SearchForm/SearchForm';
import Pager from '../../components/Pager/Pager';
import AddNewListCard from '../../components/AddNewListCard/AddNewListCard';
import NoResult from '../../components/NoResult/NoResult';
import CouponsTable from '../../components/CouponsTable/CouponsTable';
import CouponSearchForm from '../../components/CouponSearchForm/SearchForm';
import { toast } from 'react-toastify';

export const Coupons = () => {
  const history = useHistory();
  let location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [selectedUrlId, setSelectedUrlId] = useState(id);

  const [page, setPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(20);

  const [error, setError] = useState(null);

  const [coupons, setCoupons] = useState([]);
  const [initialLoadParams, setInitialLoadParams] = useState({});
  const [searchParams, setSearchParams] = useState(null);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setInitialLoadParams(params);
    }
    getPage(0);
  }, []);

  const { state, dispatch } = useContext(DrawerContext);

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const saveNewCoupon = async (data) => {
    dispatch({ type: 'SET_DRAWER_LOADING', loading: true });
    const resp = await post(`/coupons`, data);
    if (resp.status !== 201) {
      const body = await resp.json();
      toast.error('could not save new coupon');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'SET_DRAWER_ERROR', error: body.message });
      return body.error;
    } else {
      toast.success('saved new coupon');
      searchForCoupons(page, searchParams);
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'CLOSE_DRAWER' });
      return null;
    }
  };

  const openNewCouponDrawer = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'ADD_COUPON_FORM',
      data: {
        userId: id,
        propertyId: null,
        property: {},
        onSubmit: (data) => {
          saveNewCoupon(data);
        },
        onDelete: () => {}
      }
    });
  };

  function searchForCoupons(searchPage, searchData) {
    setSearchParams(searchData);
    let urlParams = null;
    if (searchData) {
      setNumberPerPage(searchData.numberPerPage || 20);
      urlParams = new URLSearchParams({
        ...searchData,
        pageNumber: (searchPage || 0).toString()
      });
    } else {
      urlParams = new URLSearchParams({
        numberPerPage: (20).toString(),
        pageNumber: (searchPage || 0).toString()
      });
    }
    urlParams && history.push({ search: urlParams.toString() });
    get('/coupons?' + urlParams)
      .then((bs) => {
        if (!bs) throw new Error('result empty');
        setCoupons(bs);
        let numPerPage = searchData?.numberPerPage || numberPerPage;
        setNumberOfPages(
          Math.ceil((bs.totalResults || bs.length || 1) / numPerPage || 20)
        );
      })
      .catch((e) => {
        setError('could not fetch coupons');
      });
  }

  function getPage(pageNumber) {
    searchForCoupons(pageNumber, searchParams);
  }

  const openEditCouponDrawer = (couponId: number) => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'EDIT_COUPON_FORM',
      data: {
        couponId: couponId,
        onSubmit: (data) => {
          editCoupon(data);
        }
      }
    });
  };

  const editCoupon = async (data) => {
    dispatch({ type: 'SET_DRAWER_LOADING', loading: true });
    const resp = await put(`/coupons/${data.id}`, data);
    if (resp.status !== 200) {
      const body = await resp.json();
      toast.error('could not edit coupon');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'SET_DRAWER_ERROR', error: body.message });
      return body.error;
    } else {
      toast.success('saved coupon changes');
      dispatch({ type: 'SET_DRAWER_LOADING', loading: false });
      dispatch({ type: 'CLOSE_DRAWER' });
      return null;
    }
  };

  function renderCoupons(coupons) {
    return (
      <CouponsTable
        coupons={coupons}
        onClick={(couponId) => {
          openEditCouponDrawer(couponId);
        }}
      />
    );
  }

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <CouponSearchForm
            onSearch={(data) => {
              searchForCoupons(page, data);
            }}
            onClear={() => setSearchParams(null)}
            filterParams={initialLoadParams}
          />

          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {coupons && coupons.length !== 0 ? (
                <>
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />

                  <AddNewListCard
                    title={'Add New Coupon'}
                    onClick={() => {
                      openNewCouponDrawer();
                    }}
                  />
                  {renderCoupons(coupons)}
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />
                </>
              ) : (
                <NoResult
                  hideButton={false}
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: 'one'
                  }}
                />
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};
