import { Fragment, useContext } from 'react';
import React from 'react';
import { DrawerContext } from '../../context/DrawerContext';

const statuses = {
  accepted: 'text-green-700 bg-green-50 ring-green-600/20',
  paid: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  rejected: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  cancelled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  canceled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type ListingAvailability = {
  id: string;
  from: string;
  to: string;
  time: string;
  cleaner: any;
  bookings: any[];
};
export default function StackedList({ items }) {
  const { state, dispatch } = useContext(DrawerContext);

  return (
    <ul role="list" className="divide-y divide-gray-100 m-2">
      {items.map((listingAvailability) => (
        <li
          key={listingAvailability.id}
          className="flex items-center justify-between gap-x-6 py-5"
        >
          <div className="min-w-0">
            <div className="flex items-start">
              <p
                className="text-md font-semibold text-gray-900 hover:cursor-pointer hover:text-hsgreen"
                onClick={() => {
                  window.open(`/cleaners/${listingAvailability.id}`);
                }}
              >
                {listingAvailability.cleaner.title}
              </p>
            </div>
            <div className="flex items-start">
              <p
                className={classNames(
                  'text-gray-900 text-md',
                  ' rounded-md mt-0.5 py-0.5 font-medium'
                )}
              >
                Availability: {listingAvailability.time}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">Bookings</p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              {listingAvailability.bookings.map((booking) => (
                <p
                  key={booking.id}
                  className={classNames(
                    statuses[booking.status],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hover:cursor-pointer'
                  )}
                  onClick={() => {
                    window.open(`/bookings/${booking.id}`);
                  }}
                >
                  #{booking.id} - {booking.time} ({booking.status})
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <button
              className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              onClick={() => {
                dispatch({
                  type: 'OPEN_DRAWER',
                  drawerComponent: 'ADD_BOOKING_FORM',
                  data: {
                    onSubmit: (data) => {},
                    onDelete: () => {},
                    cleaner: listingAvailability.cleaner
                  }
                });
              }}
            >
              Create Booking
              <span className="sr-only">, {listingAvailability.name}</span>
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
}
