import React, { useContext, useEffect, useState } from 'react';
import { withStyle } from 'baseui';
import {
  Col as Column,
  Grid,
  Row as Rows
} from '../../components/FlexBox/FlexBox';
import NoResult from '../../components/NoResult/NoResult';
import { get } from '../../helper/fetch';
import { DrawerContext } from '../../context/DrawerContext';
import Pager from '../../components/Pager/Pager';
import AddNewListCard from '../../components/AddNewListCard/AddNewListCard';
import BookingsTable from '../../components/BookingsTable/BookingsTable';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SearchForm from '../../components/SearchForm/SearchForm';
import { paramsToObject } from '../../helper/urlHelper';
import { Booking } from '../../settings/bookingTypes';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0
    }
  }
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center'
  }
}));

export default function Bookings() {
  const history = useHistory();
  let location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [selectedUrlId, setSelectedUrlId] = useState(id);

  const [page, setPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(50);

  const [error, setError] = useState(null);

  const [bookings, setBookigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [initialLoadParams, setInitialLoadParams] = useState({});
  const [searchParams, setSearchParams] = useState(null);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setInitialLoadParams(params);
    }
    getPage(0);
  }, []);

  const { state, dispatch } = useContext(DrawerContext);

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const openNewBookingDrawer = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'ADD_BOOKING_FORM',
      data: {
        onSubmit: (data) => {},
        onDelete: () => {}
      }
    });
  };

  function searchForBookings(searchPage, searchData) {
    setIsLoading(true);
    setSearchParams(searchData);
    let urlParams = null;
    if (searchData) {
      urlParams = new URLSearchParams({
        ...searchData,
        numberPerPage: numberPerPage.toString(),
        pageNumber: (searchPage || 0).toString()
      });
    } else {
      urlParams = new URLSearchParams({
        numberPerPage: numberPerPage.toString(),
        pageNumber: (searchPage || 0).toString()
      });
    }
    urlParams && history.push({ search: urlParams.toString() });
    get('/bookings?' + urlParams)
      .then((bs) => {
        if (!bs) throw new Error('result empty');
        setBookigns(bs.bookings);
        setNumberOfPages(Math.ceil(bs.totalResults / numberPerPage));
      })
      .catch((e) => {
        setError('could not fetch bookings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getPage(pageNumber) {
    searchForBookings(pageNumber, searchParams);
  }

  function renderBookings(bookings: Booking[]) {
    return (
      <BookingsTable
        bookings={bookings}
        onClick={(bookingId) => {
          window.open(`/bookings/${bookingId}`);
          setSelectedUrlId(bookingId);
        }}
      />
    );
  }

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <SearchForm
            onSearch={(data) => {
              searchForBookings(page, data);
            }}
            onChangeNumberPerPage={(numberPerPage) => {
              setNumberPerPage(numberPerPage);
            }}
            onClear={() => setSearchParams(null)}
            filterParams={initialLoadParams}
          />

          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {isLoading ? <LoadingOverlay /> : null}
            <ul className="divide-y divide-gray-200">
              {bookings && bookings.length !== 0 ? (
                <>
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />

                  <AddNewListCard
                    title={'Add New Booking'}
                    onClick={() => {
                      openNewBookingDrawer();
                    }}
                  />
                  <AddNewListCard
                    title={'Add New Group Booking'}
                    onClick={() => {
                      window.open(`/groupbookings/new`);
                    }}
                  />

                  {renderBookings(bookings)}
                  <Pager
                    currentPage={page}
                    numberOfPages={numberOfPages}
                    onPageSelect={(newPage) => {
                      setPage(newPage);
                      getPage(newPage);
                    }}
                    onNext={() => {
                      console.log(
                        `on next page ${page} number of pages ${numberOfPages}`
                      );
                      if (page <= numberOfPages) {
                        setPage(page + 1);
                        getPage(page + 1);
                      }
                    }}
                    onPrev={() => {
                      console.log(
                        `on prev page ${page} number of pages ${numberOfPages}`
                      );
                      if (page >= 1) {
                        setPage(page - 1);
                        getPage(page - 1);
                      }
                    }}
                  />
                </>
              ) : (
                <NoResult
                  hideButton={false}
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: 'one'
                  }}
                />
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </Grid>
  );
}
