import {CheckCircleIcon, CurrencyDollarIcon, EmojiSadIcon, LightBulbIcon, XCircleIcon} from '@heroicons/react/solid';
import dayjs from 'dayjs';
import React, {ReactElement} from 'react';
import {Booking, Extra} from '../../settings/bookingTypes';
/* This example requires Tailwind CSS v2.0+ */
const getStatus = (status): ReactElement => {
  switch (status) {
    case 'accepted': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
      break;
    }
    case 'requested': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <LightBulbIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
      break;
    }
    case 'paid': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CurrencyDollarIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
      break;
    }
    case 'confirmed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
      break;
    }
    case 'rejected': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <XCircleIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
      break;
    }
    case 'cancelled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
    }
    case 'canceled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
    }
    case 'completed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
            aria-hidden="true"
          />
          {status}
        </p>
      );
    }
    default: {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          unknown status {status}
        </p>
      );
    }
  }
};
const moveInOutId: number = 12;
const carpetCleaning: number = 21;
const postConstruction: number = 19;
const postRenovation: number = 20;

type BookingTableProps = {
  bookings: Booking[];
  onClick: (number) => void;
};

export default function BookingsTable(bookingTableParams: BookingTableProps) {
  /**
   ID
   Customer
   Cleaner
   Date
   Time
   Property
   Amount
   Recurrence
   Status
   */

  function printPriceField(booking): string {
    if (!booking.receipt) {
      return booking.total;
    }
    return `${booking.receipt.total}`;
  }

  const colorDependingOnExtras = (extras) => {
    if (
      extras.some(
        (extra) =>
          extra.id === moveInOutId ||
          extra.id === postConstruction ||
          extra.id === postRenovation
      )
    ) {
      return 'bg-green-100 hover:bg-green-300 cursor-pointer';
    } else if (carpetCleaningIncluded(extras)) {
      return 'bg-purple-100 hover:bg-purple-300 cursor-pointer';
    } else {
      return 'hover:bg-gray-50 cursor-pointer';
    }
  };
  const carpetCleaningIncluded = (extras: Extra[]) => {
    return extras.some((extra) => extra.id === carpetCleaning);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cleaner
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  When
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Client Pays
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Coupon
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Recurrence
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Carpet Cleaning
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cleaner payment
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Taxes
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Joined
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {bookingTableParams.bookings &&
                bookingTableParams.bookings.map((booking) => (
                  <tr
                    key={booking.id}
                    className={colorDependingOnExtras(booking.extras)}
                    onClick={() => {
                      bookingTableParams.onClick(booking.id);
                    }}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {booking.id}{' '}
                      {booking.meta?.source
                        ? `(${booking.meta?.source})`
                        : ''}
                      {booking.group_booking_id && ('Grp')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {booking.bookingClient?.given_name}{' '}
                      {booking.bookingClient?.family_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {booking.bookingCleaner?.given_name}{' '}
                      {booking.bookingCleaner?.family_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {dayjs(booking.booking_date).format('YYYY-MMM-DD')}{' '}
                      {booking.start_hour}:{booking.start_minute || '00'}-
                      {booking.end_hour}:{booking.end_minute || '00'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {booking.bookingProperty?.apartment_number &&
                        `#${booking.bookingProperty.apartment_number}`}{' '}
                      {booking.bookingProperty?.address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-green-800">
                      ${printPriceField(booking)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-green-800">
                      {booking.coupon_code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {booking.recurrance && booking.recurrance.length > 0
                        ? booking.recurrance
                        : 'One Time'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {getStatus(booking.status)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {carpetCleaningIncluded(booking.extras) ? 'Yes' : 'No'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {booking.receipt?.cleaner_total}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {booking.receipt?.tax}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {booking.bookingClient?.created_at}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
