import React, { useEffect, useState } from 'react';
import { withStyle } from 'baseui';
import {
  Col as Column,
  Grid,
  Row as Rows
} from '../../components/FlexBox/FlexBox';
import NoResult from '../../components/NoResult/NoResult';
import { useLocation } from 'react-router-dom';
import { NotificationType } from '../../settings/bookingTypes';
import { get } from '../../helper/fetch';
import NotificationsTable from '../../components/NotificationsTable/NotificationsTable';

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0
    }
  }
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center'
  }
}));

export default function Notifications() {
  let location = useLocation();
  const [error, setError] = useState(null);

  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    try {
      const response = await get('/notifications');
      setNotifications(response); // Assuming the API returns the notifications in the response data.
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  useEffect(() => {
    // Fetch notifications immediately
    fetchNotifications();

    // Set up an interval to fetch notifications every 10 seconds
    const intervalId = setInterval(fetchNotifications, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures this useEffect runs once when the component mounts and not on every re-render.

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  function renderNotifications(notifications: NotificationType[]) {
    return <NotificationsTable notifications={notifications} />;
  }

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {notifications && notifications.length !== 0 ? (
                <>{renderNotifications(notifications)}</>
              ) : (
                <NoResult
                  hideButton={false}
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: 'one'
                  }}
                />
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </Grid>
  );
}
