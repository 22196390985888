import React from "react";
import AreasMapEditor from "../../components/AreasMapEditor";
export default function WorkAreas() {
  return <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
    <div
      className="h-screen px-4 py-6 sm:px-6"
      style={{ marginBottom: '40px' }}
    >
      <AreasMapEditor />
    </div>
  </div>;
}
