import React, {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import {Extra, Receipt} from "../../settings/bookingTypes";
import {get, post} from "../../helper/fetch";
import ExtraPill from "../../components/ExtraPill/ExtraPill";
import {toast} from "react-toastify";


export default function NewGroupBooking() {
  const [extras, setExtras] = useState([] as Extra[]);
  const [coupon, setCoupon] = useState<string | null>(null);
  const [checked, setChecked] = useState([] as number[]);
  const [cleaner, setCleaner] = useState([1, 2] as number[]);
  const [receipt, setReceipt] = useState<Receipt>(null);
  const [splitReceipt, setSplitReceipt] = useState<Receipt>(null);

  const [date, setDate] = useState(new Date());
  const [groupBooking, setGroupBooking] = useState({
    hoursTotal: 10,
    date: new Date(),
    numberOfCleaners: 2,
    rate: 29,
  });

  useEffect(() => {
    recalculateReceipt();
  }, [groupBooking, checked]);

  useEffect(() => {
    if(coupon?.length > 3 || !coupon) {
      recalculateReceipt();
    }
  }, [coupon]);

  useEffect(() => {
    get('/extras')
      .then((es) => setExtras(es))
      .catch((e) => {
      });
  }, []);

  const recalculateReceipt = async () => {
    try {
      const response = await post('/bookings/receipt', {
        listingPrice: parseInt(String(groupBooking.rate)),
        hours: parseInt(String(groupBooking.hoursTotal)),
        couponCode: coupon,
        extras: checked.map((id) => ({id})),
        rate: parseInt(String(groupBooking.rate)),
        split: groupBooking.numberOfCleaners,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // If you have any response to process, you can do that here.
      const body = await response.json();
      if (body.splits) {
        setSplitReceipt(body.splits)
        setReceipt(body.receipt);
      } else {
        setSplitReceipt(body);
        setReceipt(body);
      }
      toast.success('Receipt calculated!');
    } catch (error) {
      toast.error('Failed to calculate receipt: ' + error.message);
    }
  }

  const addCleaner = () => {
    const newCleaners = [...cleaner, cleaner.length + 1];
    setCleaner(newCleaners);
    setGroupBooking({...groupBooking, numberOfCleaners: newCleaners.length});
  }

  const removeCleaner = () => {
    if (cleaner.length === 1) {
      toast.warn('Cannot remove last cleaner');
      return;
    }
    const cleanersLen = cleaner.length - 1;
    const newCleaners = [];
    for (let i = 0; i < cleanersLen; i++) {
      newCleaners.push(i + 1);
    }
    setCleaner(newCleaners);
    setGroupBooking({...groupBooking, numberOfCleaners: newCleaners.length});
  }

  const addExtra = (id) => {
    setChecked([...checked, id]);
  };
  const removeExtra = (id) => {
    const c = checked.filter((ch) => ch !== id);
    setChecked(c);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupBooking({
      ...groupBooking,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(groupBooking);
    const groupBookingRequest = {
      rate: groupBooking.rate,
      hours: groupBooking.hoursTotal,
      extras: checked,
      other_info: null,
      couponCode: coupon,
      recurrence: 'One Time',
      receipt: receipt,
      bookingDate: date,
      split: cleaner.map((c) => ({
        ...splitReceipt,
      }))
    };

    try {
      const createdBookings = await post('/bookings/group', groupBookingRequest);
      if (!createdBookings.ok) {
        throw new Error('Network response was not ok');
      }
      const {groupBookingId, bookingIds} = await createdBookings.json();
      if(bookingIds.length > 0) {
        bookingIds.forEach((id: number) => {
          window.open(`/bookings/${id}`, '_blank');
        });
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-white">
      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <h1 className="sr-only">Booking(s) summary</h1>

        <section
          className="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
        >
          <div className="mx-auto max-w-lg lg:max-w-none">
            <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
              Booking Summary ({groupBooking.numberOfCleaners} cleaner{groupBooking.numberOfCleaners > 1 ? 's' : ''})
            </h2>
            <button
              onClick={addCleaner}
              className='text-green-600'

            >
              + Add Cleaner
            </button>

            <ul role="list" className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
              <li key={'combined'} className=" py-6">
                <div className="">
                  <div className='flex flex-row justify-between'>
                    <h3>Combined for ALL cleaners</h3>
                  </div>
                  <p className="text-gray-500">Hours: {groupBooking.hoursTotal}</p>
                </div>
                <dl
                  className="hidden space-y-6 pt-6 text-sm font-medium text-gray-900 lg:block">
                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Subtotal</dt>
                    <dd>${receipt?.subtotal || 0}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Fee</dt>
                    <dd>${receipt?.fee || 0}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Taxes</dt>
                    <dd>${receipt?.tax || 0}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Coupon Covers</dt>
                    <dd>${receipt?.coupon_covers || 0}</dd>
                  </div>

                  <div className="flex items-center justify-between border-gray-200 pt-6">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">${receipt?.total || 0}</dd>
                  </div>
                </dl>
              </li>
              {cleaner.map((cleaner, idx) => (
                <li key={cleaner} className=" py-6">
                  <div className="">
                    <div className='flex flex-row justify-between'>
                      <h3>Cleaner {cleaner}</h3>
                      <button
                        onClick={removeCleaner}
                        className='text-red-500'
                      >
                        - Remove Cleaner
                      </button>
                    </div>
                    <p className="text-gray-500">Hours: {groupBooking.hoursTotal / groupBooking.numberOfCleaners}</p>
                  </div>
                  <dl
                    className="hidden space-y-6 pt-6 text-sm font-medium text-gray-900 lg:block">
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Subtotal</dt>
                      <dd>${splitReceipt?.subtotal || 0}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Fee</dt>
                      <dd>${splitReceipt?.fee || 0}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Taxes</dt>
                      <dd>${splitReceipt?.tax || 0}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Coupon Covers</dt>
                      <dd>${splitReceipt?.coupon_covers || 0}</dd>
                    </div>

                    <div className="flex items-center justify-between border-gray-200 pt-6">
                      <dt className="text-base">Total to Client</dt>
                      <dd className="text-base">${splitReceipt?.total || 0}</dd>
                    </div>
                  </dl>
                </li>

              ))}
            </ul>

          </div>
        </section>

        <form className="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16"
              onSubmit={handleSubmit}>
          <div className="mx-auto max-w-lg lg:max-w-none">
            <section aria-labelledby="contact-info-heading">
              <h2 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                Group Booking Information
              </h2>

              <button onClick={recalculateReceipt} type='button'>
                calc rec
              </button>

              <div className="mt-6">
                <label htmlFor="hoursTotal" className="block text-sm font-medium text-gray-700">
                  Total hours
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="hoursTotal"
                    name="hoursTotal"
                    value={groupBooking.hoursTotal}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label htmlFor="rate" className="block text-sm font-medium text-gray-700">
                  Rate $
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="rate"
                    name="rate"
                    value={groupBooking.rate}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label htmlFor="couponCode" className="block text-sm font-medium text-gray-700">
                  Coupon
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="couponCode"
                    name="couponCode"
                    value={coupon || ''}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label htmlFor="date-picker" className="block text-sm font-medium text-gray-700">
                  Date
                </label>
                <DatePicker
                  className={'mt-1 border border-gray-300 rounded-md shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm cursor-pointer'}
                  selected={date}
                  onChange={(val) => setDate(val)}
                />
              </div>

              <div className="mt-6">
                <label htmlFor="date-picker" className="block text-sm font-medium text-gray-700">
                  Extras
                </label>
                <div className="">
                  <div className="mt-4">
                    {extras &&
                      extras.length > 0 &&
                      extras.map((e) => {
                        return (
                          <ExtraPill
                            id={e.id}
                            key={e.id}
                            name={e.name}
                            content={'+$' + e.cost}
                            checked={checked.includes(e.id)}
                            disabled={false}
                            onChecked={addExtra}
                            onUnChecked={removeExtra}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
              <button
                type="submit"
                className="w-full rounded-md border bg-green-500 hover:bg-green-600 text-white py-2 px-4 text-center"
              >
                Create
              </button>
            </div>
            <p className='text-gray-500'>* Created bookings will be assigned to Home Spritz. Once created transfer to
              appropriate cleaners.</p>
            <p className='text-gray-500'>* To edit individual receipts after creation navigate to opened tabs</p>
            <p className='text-gray-500'>* You&apos;ll need to set start/end times afterwards</p>
          </div>
        </form>
      </div>
    </div>
  );
}