// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const BOOKINGS = '/bookings';
export const GROUP_BOOKINGS = '/groupbookings';
export const SINGLE_BOOKING = '/bookings/:id';
export const CUSTOMERS = '/clients';
export const CUSTOMER = '/clients/:id';
export const CLEANERS = '/cleaners';
export const CLEANER = '/cleaners/:id';
export const CLEANER_SCHEDULE = '/cleaners/:id/schedule';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const AVAILABILITY = '/availability';
export const NOTIFICATIONS = '/notifications';
export const STUFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const WORK_AREAS = '/work-areas';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';

export const recurrences = [
  'One Time',
  'once a week',
  'bi-weekly',
  'three weeks',
  'monthly',
  'six weeks',
];

export const AUTH_HEADER = 'Authorization';
