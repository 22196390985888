import React, { useEffect, useState } from 'react';

import {
  QuestionMarkCircleIcon,
  EyeIcon,
  EyeOffIcon
} from '@heroicons/react/solid';
import { get, post, put } from '../../helper/fetch';
import { Cleaner } from '../../settings/bookingTypes';
import { toast } from 'react-toastify';

type ReviewsProps = { listingId: number };
type Reviews = Cleaner['listing']['reviews'];

const Reviews: React.FC<ReviewsProps> = ({ listingId }) => {
  const [addReviewButtonPressed, setAddReviewButtonPressed] = useState(false);
  const [newReviewBookingId, setNewReviewBookingId] = useState(null);
  const [reviews, setReviews] = useState<Reviews>([]);

  useEffect(() => {
    get(`/cleanerlistings/${listingId}/reviews`)
      .then((data) => {
        setReviews(
          data.map((r: any) => ({
            ...JSON.parse(r.review),
            id: r.id,
            hidden: r.hidden
          }))
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error('could not fetch reviews');
        setReviews([]);
      });
  }, [listingId]);

  const addClientReview = async () => {
    try {
      const resp = await post(
        `/cleanerlistings/${listingId}/clientreview/${newReviewBookingId}`,
        {}
      );
      if (resp.status !== 201 && resp.status !== 200) {
        toast.error('could not add client review');
      } else {
        toast.success('client review ready...');
        setNewReviewBookingId(null);
        const body = await resp.json();
        window.open(body.url, '_blank');
      }
    } catch (e) {
      toast.error('could not add client review');
    }
  };

  return (
    <section aria-labelledby="reviews-title">
      <div className="bg-white shadow sm:rounded-lg border-hsgreen border sm:overflow-hidden">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-6 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-hsgreen">
              Client reviews
            </h2>
            <button
              type="submit"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
              onClick={() => setAddReviewButtonPressed(!addReviewButtonPressed)}
            >
              {!addReviewButtonPressed
                ? 'Add Client Review'
                : 'Hide Review Form'}
            </button>
          </div>
          {addReviewButtonPressed && (
            <div className="bg-gray-50 px-4 py-6 sm:px-6">
              <div className="flex">
                <div className="min-w-0 flex-1">
                  <form action="#">
                    <div>
                      <label htmlFor="comment" className="sr-only">
                        Client Review Body
                      </label>
                    </div>
                    <div className="mt-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Booking id
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="text"
                          id="client-id"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="booking id..."
                          aria-describedby="client-id-description"
                          value={newReviewBookingId}
                          onChange={(e) => {
                            try {
                              const bookingId = parseInt(e.target.value);
                              setNewReviewBookingId(bookingId);
                            } catch (e) {}
                          }}
                        />
                      </div>
                      <p
                        className="mt-2 text-sm text-gray-500"
                        id="email-description"
                      >
                        Copy paste booking id here...
                      </p>
                    </div>

                    <div className="mt-3 flex items-center justify-between">
                      <p className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
                        <QuestionMarkCircleIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span>You will be taken to the review page</span>
                      </p>
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={newReviewBookingId === null}
                        onClick={addClientReview}
                      >
                        Add Client Review
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          <div className="px-4 sm:px-6">
            <ul className="m-4 space-y-8">
              {reviews?.map((clientReview) => (
                <li key={clientReview.id}>
                  <div className="flex justify-between">
                    <div className="">
                      <div className="text-sm">
                        <a href="#" className="font-medium text-gray-900">
                          {!!clientReview.hidden && (
                            <p className="text-gray-400">HIDDEN</p>
                          )}
                          {clientReview.author_name ||
                            'author name missing, id: ' + clientReview.id}
                        </a>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>{clientReview.text}</p>
                      </div>
                      <div className="mt-2 text-sm space-x-2">
                        <span className="text-gray-500 font-medium">
                          {clientReview.created_at}
                        </span>{' '}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button>
                        {!!!clientReview.hidden ? (
                          <div
                            className="flex flex-row"
                            onClick={() => {
                              put(
                                `/cleanerlistings/${listingId}/reviews/${clientReview.id}`,
                                { hidden: true }
                              )
                                .then((data) => {
                                  if (data.status !== 200) {
                                    throw new Error('could not hide review');
                                  }
                                  setReviews(
                                    reviews.map((r) => {
                                      if (r.id === clientReview.id) {
                                        r.hidden = true;
                                      }
                                      return r;
                                    })
                                  );
                                })
                                .catch((err) => {
                                  console.log(err);
                                  toast.error('could not hide review');
                                });
                            }}
                          >
                            <EyeIcon
                              className="h-5 w-5 ml-4"
                              aria-hidden="true"
                            />
                          </div>
                        ) : (
                          <div
                            className="flex flex-row"
                            onClick={() => {
                              put(
                                `/cleanerlistings/${listingId}/reviews/${clientReview.id}`,
                                { hidden: false }
                              )
                                .then((data) => {
                                  if (data.status !== 200) {
                                    throw new Error('could not show review');
                                  }
                                  setReviews(
                                    reviews.map((r) => {
                                      if (r.id === clientReview.id) {
                                        r.hidden = false;
                                      }
                                      return r;
                                    })
                                  );
                                })
                                .catch((err) => {
                                  console.log(err);
                                  toast.error('could not make review visible');
                                });
                            }}
                          >
                            <EyeOffIcon
                              className="h-5 w-5 ml-4"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
