import React, {useState} from 'react';
import SelectBasic from "../SelectBasic/SelectBasic";

type AvailabilityDayMap = {
  monday: {
    from: string;
    till: string;
  },
  tuesday: {
    from: string;
    till: string;
  },
  wednesday: {
    from: string;
    till: string;
  },
  thursday: {
    from: string;
    till: string;
  },
  friday: {
    from: string;
    till: string;
  },
  saturday: {
    from: string;
    till: string;
  },
  sunday: {
    from: string;
    till: string;
  }
}

const genTimes = () => {
  let times = [];
  times.push({label: 'none', value: 'none'});

  for (let i = 5; i <= 23; i++) {
    times.push({value: i, label: `${i}:00`});
  }
  return times;
};

const defaultSched = {
  monday: {from: 'none', till: 'none'},
  tuesday: {from: 'none', till: 'none'},
  wednesday: {from: 'none', till: 'none'},
  thursday: {from: 'none', till: 'none'},
  friday: {from: 'none', till: 'none'},
  saturday: {from: 'none', till: 'none'},
  sunday: {from: 'none', till: 'none'}
}

const EditCleanerAvailability = ({availability: availability , onSave}) => {
  const [editedAvailability, setEditedAvailability] = useState(availability || defaultSched);
  const availableTimes = genTimes();

  const getDayTableRows = (availabilityDayMap: AvailabilityDayMap, availableOptions: { value: string, label: string }[]) => {
    let items = [];
    for (const day in availabilityDayMap) {
      items.push(<div key={day} className={'flex flex-row bg-white'}>
        <div className="w-20 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{day.charAt(0).toUpperCase() + day.slice(1)}</div>
        <div className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div className="ml-2 w-full">
            <SelectBasic
              options={availableOptions}
              selected={{value: editedAvailability[day]['from'] || 'none', label: editedAvailability[day]['from'] || 'none'}}
              onSelect={(value) => {
                setEditedAvailability({
                  ...editedAvailability,
                  [day]: {from: value.label, till: editedAvailability[day]['till']}
                })
              }}
            />
          </div>
        </div>
        <div className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div className="ml-2 w-full">
            <SelectBasic
              options={availableOptions}
              selected={{value: editedAvailability[day]['till'] || 'none', label: editedAvailability[day]['till'] || 'none'}}
              onSelect={(value) => {
                setEditedAvailability({
                  ...editedAvailability,
                  [day]: {from: editedAvailability[day]['from'], till: value.label}
                })
              }}
            />
          </div>
        </div>
      </div>)
    }

    return items;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="min-w-full divide-y divide-gray-200">
              <div className="bg-gray-50">
                <div className='flex flex-row place-content-between'>
                  <div
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Day
                  </div>
                  <div
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    From
                  </div>
                  <div
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    To
                  </div>

                </div>
              </div>
              <div className='flex flex-col'>
                {getDayTableRows(editedAvailability, availableTimes).map((day) => {
                  return day
                })}
              </div>
            </div>
            <div
              className={"block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"}>
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => {
                  onSave(editedAvailability)
                }}
              >
                Save Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCleanerAvailability;