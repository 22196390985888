import React, { useContext } from 'react';
import { Coupon } from '../../settings/bookingTypes';
import { DrawerContext } from '../../context/DrawerContext';
import { post, put } from '../../helper/fetch';
import { toast } from 'react-toastify';

type Props = {
  coupons: Coupon[];
  onClick: (id: number) => void;
};
export default function CouponsTable({ coupons, onClick }: Props) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    % Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    $ Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Active
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    One Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {coupons &&
                  coupons.map((coupon) => (
                    <tr
                      key={coupon.id}
                      className={''}
                      onClick={() => {
                        onClick(coupon.id);
                      }}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {coupon.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {coupon.name}{' '}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {coupon.amount ? `${coupon.amount}%` : ''}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-green-800">
                        {coupon.exact_amount ? `$${coupon.exact_amount}` : ''}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-hrey-800">
                        {coupon.active === 0 ? 'No' : 'Yes'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                        {coupon.one_time ? 'No' : 'Yes'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
