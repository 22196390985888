import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid';

const getPages = (currentPage, numberOfPages, onPageSelect) => {
  const selectedBorder =
    'cursor-pointer border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
  return (
    <div className="hidden md:-mt-px md:flex">
      <div className={selectedBorder}>
        {currentPage} / {numberOfPages}
      </div>
    </div>
  );
};

export default function Pager({
  currentPage,
  numberOfPages,
  onPageSelect,
  onNext,
  onPrev
}) {
  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex pl-4">
        <div
          onClick={onPrev}
          className="cursor-pointer border-t-2 border-transparent pt-4 pb-5 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </div>
      </div>
      {getPages(currentPage, numberOfPages, onPageSelect)}
      <div className="-mt-px w-0 flex-1 flex justify-end pr-4">
        <div
          onClick={() => {
            onNext();
          }}
          className="cursor-pointer border-t-2 border-transparent pt-4 pb-5 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Next
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </nav>
  );
}
