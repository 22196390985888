import fetch from 'isomorphic-unfetch';
import { AUTH_HEADER } from '../settings/constants';
import Firebase from './firebase';
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5005';

const firebase = Firebase.getInstance();

const getAuthHeader = async () => {
  return (
    (await firebase.getAuth().currentUser?.getIdToken(false)) ||
    localStorage.getItem(AUTH_HEADER) ||
    ''
  );
};

const getHeaders = async () => {
  const token = await getAuthHeader();
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: token
  };
};

const getFileHeader = async () => {
  const token = await getAuthHeader();
  return {
    Authorization: token
  };
};

export const get = async (url: string, authHeader?: string): Promise<any> => {
  const headers = await getHeaders();
  return fetch(BASE_URL + url, {
    method: 'GET',
    headers: headers
  })
    .then((result) => {
      if (result.status > 299) {
        throw Error('request error');
      }
      return result.json();
    })
    .catch((e) => {
      throw e;
    });
};

export const post = async (
  url: string,
  data: any,
  authHeader?: string,
  isFile = true
): Promise<Response> => {
  if (isFile) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify(data)
    });
  } else {
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: await getFileHeader(),
      body: data
    });
  }
};

export const put = async (
  url: string,
  data: any,
  authHeader?: string,
  isFile = true
): Promise<Response> => {
  if (isFile) {
    return fetch(BASE_URL + url, {
      method: 'PUT',
      headers: await getHeaders(),
      body: data ? JSON.stringify(data) : ''
    });
  } else {
    return fetch(BASE_URL + url, {
      method: 'PUT',
      headers: await getFileHeader(),
      body: data
    });
  }
};

export const del = async (
  url: string,
  authHeader?: string
): Promise<Response> => {
  return fetch(BASE_URL + url, {
    method: 'DELETE',
    headers: await getHeaders()
  });
};
